.profile_wrapper {
    margin-top: 3rem;
    margin-bottom: 4rem;
    .sidebar {
        background: #fff;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        padding-bottom: 4rem;

        .profile_head {
            text-align: center;
            margin-bottom: 2rem;

            .s_1 {
                position: relative;
                z-index: 0;
                padding-top: 38px;
                margin-bottom: 15px;

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 50%;
                    border: 4px solid #fff;
                }
                
                &:after {
                    content: '';
                    width: 100%;
                    height: 90px;
                    background-image: url(../../images/profile_bg.jpg);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    background-position: center;
                    background-size: cover;
                }
            }

            h4 {
                font-size: 1.3rem;
                margin-bottom: 1rem;
            }

            table {
                width: 90%;
                margin: 0 auto 2rem;

                h5 {
                    color: var(--primary);
                    font-size: 20px;
                    margin-bottom: 5px;
                }

                h4 {
                    font-size: 14px;
                    margin: 0;
                }

                td {
                    padding: 15px 5px;
                    width: 50%;
                    background: #f5f5f5;
                }
                
            }
        }

        .side_menus{
            padding: 0 15px;

            ul {
                padding: 0;

                li {
                    list-style: none;
                    margin-bottom: 8px;
                    position: relative;
                    
                    a{
                        color: #666;
                        display: flex;
                        align-items: center;
                        padding: 7px 10px;
                        border-left: 3px solid transparent;
                        font-weight: 500;
                        position: relative;

                        svg {
                            font-size: 15px;
                            margin-right: 7px;
                            color: #888;
                        }

                        &:hover{
                            background: #f3f5ff;
                            color: var(--primary);
                        }
                        
                        &:after {
                            content: '';
                            border: solid var(--primary);
                            border-width: 0 3px 3px 0;
                            display: inline-block;
                            padding: 3px;
                            transform:
                            rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            position: absolute;
                            right: 10px;
                            opacity: 0;
                        }

                        &:hover:after {
                            opacity: 1;
                        }

                    }
                }
            }
            
        }
        
    }


    .s_detailed {
        background: #fff;
        padding: 30px 15px;
        border-radius: 10px;

        h1 {
            font-size: 1.6rem;
            color: #444;
            line-height: 20px;
            border-bottom: 1px solid #eee;
            margin-bottom: 10px;
            padding-bottom: 15px;
        }

        h3 {
            font-size: 1.2rem;
            color: #444;
        }

        .box_1 {
            border: 1px solid #eee;
            margin-top: 30px;
            margin-bottom: 30px;
            padding: 30px 20px 35px 20px;
            border-radius: 10px;
            background: #f5f5f5;

            h3 {
                font-size: 1.4rem;
                margin-bottom: 15px;
            }

            .form-group label {
                margin-bottom: 5px;
                font-size: 14px;
            }
        }
    }
}









  