#reports {
  background: #ffffff;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .image-item {
    img {
      object-fit: cover;
      border: 1px solid #ddd;
    }
  }
}
