.top-banner {
  margin-bottom: 0px !important;
  position: relative;
  img {
    height: calc(100vh - 200px);
    width: 100%;
    object-fit: cover;
  }

  .container{
    padding: 0 20px !important;
    position: relative;
    background: none !important;

    .content {
      position: absolute;
      top: 0;
      z-index: 1;
      background: #0c3c60d6;
      color: #fff;
      padding: 1rem 2rem;
      margin-top: calc(100vh - 77vh);
      border-radius: 10px;

      h1 {
        margin-bottom: 24px;
      }
      h4 {
        display: flex;
        align-items: center;
        
        svg {
          margin-right: 10px;
       }
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .top-banner .container .content h1 {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }

  .top-banner .container .content h4 {
    font-size: 1rem;
  }
  .top-banner .container .content {
    padding: 1rem 1rem;
}

}