.footer_wrapper{
    background: var(--primary);
    

    h3 {
        font-size: 1.3rem;
        color: var(--primary);
        font-weight: 600;
        margin-bottom: 15px;
    }

    ul {
        padding-left: 15px;

        li {
            margin-bottom: 10px;
    
            a {
                color: #212529;
            }
        }


    }
    
    .footer_bootom {
        padding-top: 2.5rem;
        padding-bottom: 1.3rem;
        color: #fff;
    }

    .footerlinks {
        text-align: right;

        a {
            color: #444;
            text-decoration: none;
        }
        
    }

}