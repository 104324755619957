.whole-content #location {
  position: relative;
  .iframe_wrapper {
    iframe {
      width: 100%;
      height: 70vh;
    }
  }
  .container {
    padding: 0;

    .section-desc {
      text-align: center;
      background: #edf4ff;
      max-width: 750px;
      margin: -110px auto 0;
      padding: 30px;
      border-radius: 10px;
      position: relative;

      .section-head {
        margin: 0 0 20px 0;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .whole-content #location .container .section-desc {
    padding: 20px !important;
  }
  .whole-content #location .container .section-desc .section-head {
    margin: 0 0 10px 0;
  }
}
