.ribbon {
 
  background-color: var(--primary);

  z-index: 10;
  margin-bottom: 3rem;

  @media (min-width: 1024px) {
    position: sticky;
    top: 0;
  }

  .ribbon-item {
    cursor: pointer;
    padding: 15px 35px;
    font-size: 1.1rem;
    color: #fff;
    border-right: 1px solid #ddd;
    text-align: center;
    transition: 0.5s;

    &:last-child {
      border: none;
    }
    &:hover {
      background: #055a99;
      color: #fff;
    }
  }
}
