.accordion-item {
  list-style: none;
  margin-bottom: 10px;
  box-shadow: 0 5px 5px -2px #ccc;
}
.accordion-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: #eee;
  border: 1px solid #ddd;
  padding: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .acc-head {
    font-size: 14px;
    font-weight: bolder;
  }

 
}
.accordion-toggle:hover {
  background-color: #e5e5e5;
 
}
.accordion-toggle h3 {
  margin: 0;
}
.accordion-content {
  border-top: none;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 1em;
}
