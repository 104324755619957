.custom-light-box {
  /* modal */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.8);
    display: flex;
    align-items: center;
  }
  .overlay img,
  video {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  }
  .overlay > span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
  }
  .overlay-arrows_left {
    cursor: pointer;
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    left: 0;
    z-index: 999;

    &:active {
      background-color: #4e4c4c;
    }
  }

  .overlay-arrows_left svg {
    width: 50px;
    height: 50px;
    color: white;
  }

  .overlay-arrows_right svg {
    width: 50px;
    height: 50px;
    color: white;
  }

  .overlay-arrows_right {
    cursor: pointer;
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 999;

    &:active {
      background-color: #4e4c4c;
    }
  }
}

.custom-alamo-img {
  object-fit: cover;
  border-radius: 5px;
  height: 300px;
  margin-right: 1rem;
  width: 350px;
  box-shadow: 0 0 2px 1px #ccc;
}

#__next {
  padding: 20px;
}
.image-item {
  padding: 20px 0 20px 20px;
  height: 300px;

  img,
  video {
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 0 2px 1px #ccc;
  }
}

.react-multiple-carousel__arrow {
  z-index: 10;
  background: var(--primary);
}
.react-multiple-carousel__arrow--left {
  left: calc(2% + 1px);
}
.react-multiple-carousel__arrow--right {
  right: calc(2% + 1px);
}

@media screen and (max-width: 768px) {
  .react-multiple-carousel__arrow {
    min-width: 30px;
    min-height: 30px;
  }
  .react-multiple-carousel__arrow::before {
    font-size: 15px;
    left: -1px;
  }
  .image-item {
    height: 200px;
  }
  .custom-light-box .overlay-arrows_left svg,
  .custom-light-box .overlay-arrows_right svg {
    width: 40px;
    height: 30px;
    line-height: 48px;
  }
  .custom-light-box .overlay-arrows_left,
  .custom-light-box .overlay-arrows_right {
    top: 50%;
    width: 40px;
    height: 50px;
    line-height: 45px;
  }
}

.custom-play-button {
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  .play-info {
    color: #eee;
  }
}
