

.design_2{
    margin-top: 1rem;
    margin-bottom: 1rem;
    .img_sec{
        height: 100%;
        img{
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }
    .section-head{
        margin: 0 0 15px 0 !important;
        border: none;
    }
}

@media screen and (max-width: 768px) {
    .design_2 {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
}