:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --primary: #0c3c60;
  --black: #000000;
}

.btn-primary {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}
.btn-primary:hover {
  background: var(--black) !important;
  border-color: var(--black) !important;
}

a {
  color: var(--primary);
  text-decoration: none !important;
}
body .btn {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 6px;
}
.s_padding {
  padding-left: 3rem;
  padding-right: 3rem;
}

img {
  max-width: 100%;
}
.sec_t {
  font-size: 1.4rem;
  color: var(--primary);
  border-left: 4px solid;
  padding-left: 7px;
  line-height: 1;
  margin-bottom: 2rem;
  font-weight: 600;
}
.form-control:focus {
  box-shadow: none !important;
}
li {
  margin-bottom: 1rem;
}
.divider {
  border-bottom: 1px solid #ddd;
  margin-top: 3rem;
  margin-bottom: 1rem;
  clear: both;
  overflow: hidden;
}
body .container {
  max-width: 1340px;
}

.whole-content {
  section {
    margin-bottom: 3rem;
    position: relative;
    overflow: hidden;
    .container {
      .section-head {
        margin: 1rem 0 1rem;
      }
    }
  }
}

#project-spec {
  .section-desc {
    padding: 0 1rem;
    z-index: 9;
    display: flex;
    flex-flow: wrap;

    .accordion-item {
      list-style: none;
      margin-bottom: 10px;
      box-shadow: none;
      width: 48.9%;
      margin-right: 2%;
      margin-bottom: 20px;

      &:nth-child(even) {
        margin-right: 0;
      }
      .accordion-toggle {
        background: #fff;

        &:hover {
          background: #f5f5f5;
        }
      }
      .accordion-content {
        border-top: none;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  body .container {
    width: 95%;
  }
  body .modal .container {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .search_wrapper .search_inner .search_btn {
    max-width: 120px;
    padding: 5px 10px;
    margin: 0 4px 8px;
    height: 40px;
    font-size: 0.9rem;
  }
  .search_wrapper .search_inner .search_header {
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  .ribbon .ribbon-item {
    padding: 15px 20px;
    font-size: 14px;
  }

  .search_wrapper .search_inner .search_header .search_w input {
    height: 45px;
    font-size: 0.9rem;
  }
  .search_wrapper .search_inner .search_header .search_w svg {
    font-size: 17px;
  }
  .search_wrapper .search_inner .search_btn svg {
    margin-left: 0;
  }
  .property_de1_inr .head_nav ul {
    display: block;
  }
  .property_de1_inr .head_nav ul li {
    margin-right: 5px;
    margin-bottom: 15px;
  }
  .property_de1_inr .head_nav ul li a {
    font-size: 0.8rem;
  }
  .title_action {
    text-align: center;
  }

  .s_header .s_header_inr {
    text-align: center;
    padding-bottom: 1.5rem;
  }
  .login_wrapper .l_inner {
    padding: 3rem 2rem 2rem !important;
  }
  .login_wrapper {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}

@media screen and (max-width: 767px) {
  .search_wrapper .search_inner .search_btn {
    max-width: 111px;
    padding: 5px 5px;
    margin: 0 4px 5px;
  }
  .s_header h1 {
    font-size: 1.5rem;
  }
  .search_wrapper .search_inner {
    padding: 1rem 0.5rem;
  }
  .search_wrapper .search_inner .search_header .search_w input {
    padding-right: 40px;
  }
  body .sp_sec .single_sp .sp_cnt h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    margin-top: 1.2rem;
  }
  body .sp_sec .single_sp {
    margin-bottom: 35px;
  }
  .section-desc,
  .design_2 .section-head {
    padding: 0rem !important;
  }
  .banner_wrapper {
    margin-bottom: 0;
    padding: 2rem 1rem;
  }
  .banner_wrapper .banner_inner h2 {
    font-size: 2rem;
  }

  .header_wrapper {
    padding: 10px;
  }

  .header_wrapper .head_menu a {
    padding: 5px 20px;
    font-size: 0.8rem;
  }

  .logo img {
    max-width: 80px;
  }
  .banner_wrapper .banner_inner .desc,
  .banner_wrapper .banner_inner .button_p .btn {
    font-size: 1rem;
  }
  .header_wrapper .head_menu a:last-child {
    margin-left: 10px;
  }
  .header_wrapper .act_head {
    padding: 3px 10px;
  }
  .footer_wrapper {
    padding: 4rem 1rem 1rem;
  }
  .property_de1_inr .row {
    flex-direction: column-reverse;
  }
  body .container {
    width: 100%;
  }
  .invest_wrapper .inv_det .react-tabs__tab span {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 1rem;
  }
  .invest_wrapper .inv_det .react-tabs__tab {
    font-size: 0.9rem;
  }
  .invest_wrapper .inv_det .react-tabs__tab:after {
    height: 5px;
    top: 25px;
  }
  .inv_wrapper .s_detailed .box_1 {
    padding: 30px 0px 0px 0px;
  }
  .invest_wrapper .inv_det .offer_box {
    padding: 30px 15px;
  }
  .invest_wrapper .inv_det .tab_cnt {
    margin-top: 1rem;
  }
  .section-head {
    font-weight: bold;
    font-size: 1.7rem !important;
  }
  .ribbon .ribbon-item {
    padding: 5px 15px;
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .ribbon {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .ribbon .ribbon-item:last-child {
    border: 1px solid #ddd;
    margin-right: 0;
  }
  #project-spec .section-desc .accordion-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .whole-content section .container {
    width: 95%;
    padding: 20px 10px 40px 10px;
  }
  .ribbon .ribbon-item {
    padding: 5px 15px;
    font-size: 13px;
  }
}

.top-loader {
  display: none;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  width: 125px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
  background: #343a40;
  color: white;
  font-size: 12px;
  padding: 3px 0;
  z-index: 1000000;
}

.loading-indicator {
  .top-loader {
    display: block;
  }
  .whole-content {
    pointer-events: none;
    opacity: 0.6;
  }
}

table {
  th,
  td {
    white-space: nowrap !important;
  }
}

.section-head {
  margin: 2rem 0;
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary);
  padding: 0 1rem;
}

.section-desc {
  padding: 0 1rem;
  z-index: 9;
}

.section-sub {
  display: flex;
  margin-bottom: 2rem;
  padding: 0 1rem;
  flex-wrap: wrap;

  .sub-item {
    margin-bottom: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--primary);
    padding: 0.3rem 1rem;
    &.active {
      color: white;
      background-color: var(--primary);
    }
  }
}

.gallery-img {
  img {
    height: 10rem;
    width: 100%;
    object-fit: cover;
    margin-bottom: 1rem;
  }
}

.swiper-slide {
  img {
    height: 250px !important;
    width: 100% !important;
    object-fit: cover;
  }
}

.nav-tabs {
  .nav-item {
    margin-bottom: 0 !important;
  }
}
