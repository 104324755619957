.sp_sec {
  .single_sp {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 20px;

    &:last-child {
      border: none;
      padding-bottom: 0px;
    }
    .sp_cnt h3 {
      font-size: 1.6rem;
      margin-bottom: 1.2rem;

      span {
        margin-left: 0.5rem;
        font-size: 1.2rem;
        color: rgb(112, 112, 112);
      }
    }
    .sp_cnt {
      padding: 0 1rem;
    }
    .sp_img {
      text-align: center;
      background: #ffffff;
      height: 100%;
      padding: 20px;
      border-radius: 10px;

      img {
        max-width: 210px;
        border-radius: 50%;
        // border: 6px solid #fff;
        width: 100%;
        height: 210px;
        object-fit: cover;
        object-position: top;
      }
    }
  }
}

#dev_team {
  background: #edf4ff;
}

#cont_team {
  background: #ffffff;
  padding-top: 2rem;
  padding-bottom: 3rem;
  margin-bottom: 0px;
  .sp_sec .single_sp .sp_img {
    background: #edf4ff;
  }
}

#project-spec .section-desc .accordion-item .accordion-toggle {
  background: var(--primary) !important;
  color: #fff;
}
#project-spec .section-desc .accordion-item .accordion-toggle:hover {
  background: var(--primary) !important;
}
