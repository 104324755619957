.property_wrapper {
  .container {
    max-width: 1100px;
  }

  .p_header {
    background: var(--primary);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 30px;
    border-radius: 10px;
  }

  .s_header {
    margin-top: 10px;

    .h_text {
      margin-bottom: 11px;
      display: inline-block;
      background: #4a84b2;
      color: #fff;
      padding: 6px 10px;
      border-radius: 0;
      font-size: 14px;
    }

    .s_btn {
      text-align: right;

      .btn {
        max-width: 180px;
        width: 100%;
        padding: 8px 10px;
        font-size: 15px;
      }
    }

    h2 {
      font-size: 30px;
      margin: 0;
    }
  }
  .s_banner {
    margin-bottom: 25px;

    .sbanner_inr {
      min-height: 350px;
      height: 100%;
      position: relative;
      margin-top: 15px;
      border-radius: 10px;
      overflow: hidden;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -1;
      }

      .cnt {
        position: relative;
        z-index: 1;
        text-align: center;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #fff;

        h2 {
          font-weight: bold;
          font-size: 40px;
        }
        p {
          font-size: 25px;
          font-weight: 400;
        }
      }

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #00000096;
        z-index: -1;
      }

      .f_sec {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .icon {
          width: 60px;
          height: 60px;
          border: 1px solid var(--primary);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;

          img {
            width: 35px;
          }
        }

        .cont h3 {
          font-size: 18px;
          font-weight: normal;
          margin-bottom: 5px;
        }
        .cont h4 {
          margin: 0;
          color: var(--primary);
          font-size: 23px;
        }
      }
    }
  }

  .s_feature {
    margin-bottom: 20px;

    .f_sec {
      background: #fff;
      padding: 10px;
      display: flex;
      margin-bottom: 20px;
      align-items: flex-end;
      border: 1px solid #eee;
      border-radius: 10px;

      .icon {
        width: 70px;
        height: 70px;
        border: 1px solid #eee;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        img {
          width: 40px;
        }
      }

      .cont h3 {
        font-size: 20px;
        font-weight: normal;
      }
      .cont h4 {
        font-size: 20px;
        color: var(--primary);
      }
    }
  }

  .s_more {
    margin-bottom: 50px;

    .mr_over {
      background: #fff;
      padding: 20px 15px;
      border-radius: 10px;
    }

    h4 {
      margin-bottom: 15px;
    }

    .h_section {
      background: #fff;
      padding: 20px 15px;
      border-radius: 10px;

      height: 100%;
    }
    .mr_det {
      margin-bottom: 50px;
    }

    .docs a {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      svg {
        color: var(--primary);
        margin-right: 5px;
        font-size: 19px;
        width: 20px;
      }
    }
  }
}

.modal-header {
  background: var(--primary);
  color: #fff;
}
.modal-header .btn-close {
  color: #fff;
  background-color: #fff;
  opacity: 1;
  margin-right: 10px !important;
}
.sp_wrapper{

  .single_sp{
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 30px;
    border: 1px solid #eee;
    background: #f6f6f6;

    .sp_img {
      height: 100%;
      display: flex;
      align-items: center;
      background: #fff;
      padding: 5px;
      border-radius: 10px;

       img {
        border-radius: 10px;
        object-fit: cover;
      }
    }

    .sp_cnt {

      h3 {
        margin: 0 0 5px 0;
        font-size: 20px;
      }

      h4 {
        font-size: 17px;
        color: #444;
        margin-bottom: 10px !important;
        font-weight: normal;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
    


  }
} 


@media screen and (max-width: 767px) {
  .property_wrapper .logo img {
    max-width: 110px;
  }
  .property_wrapper .p_header {
    margin-top: 15px;
  }
  .property_wrapper .s_header {
    margin-top: 10px;
    text-align: center;
  }
  .property_wrapper .s_header .s_btn {
    text-align: center;
    margin-top: 15px;
  }
  .property_wrapper .s_header .s_btn .btn {
    max-width: initial;
    padding: 7px 15px;
    font-size: 14px;
    margin-top: 1px;
    display: inline-block;
    width: auto;
    margin-bottom: 5px;
  }
  .table th,
  .table td {
    font-size: 13px;
    line-height: 1.3;
  }
  .property_wrapper .s_banner .sbanner_inr {
    min-height: 270px;
  }
  .mr_over {
    margin-bottom: 30px;
  }
  .wrap-custom {
    flex-wrap: wrap;
    justify-content: center;
  }

  .wrap-custom .btn {
    width: auto !important;
    font-size: 14px;
    padding: 7px 15px;
  }
}

.carousel-item {
  img {
    border-radius: 5px;
  }
}

.txt-shadow {
  text-shadow: 2px 2px #222;
}

.carousel-caption {
  background: #45454594;
}

.theme-bg-table {
  th {
    background-color: #0c3c60 !important;
    color: #ffffff;
    font-weight: 500;
  }
}

.card-like {
  padding: 15px 25px;
  box-shadow: inset 0 0 5px 0px #ccc;
  background-color: rgba(236, 236, 236, 0.662);
  border-radius: 5px;
  margin-bottom: 1rem;
  text-align: center;

  label {
    font-size: 12px;
    font-weight: 600;
  }

  .value {
    font-size: 20px;
    font-weight: 600;
    color: #0c3c60;
  }
}

.custom-bg-footer {
  th {
    background-color: #d0e3ec !important;
    color: #222;
    font-weight: 500;
  }
}

.wrap-custom {
  flex-wrap: wrap;
  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
}
